import React, { useState, useEffect } from 'react'
import CommonPopup from '../../components/PopupForm/CommonPopup';
import * as Yup from 'yup';
import Link from 'next/link';

export default function BorderBtn({ slug=null, buttonTitle,product_name=null,  buttonLink=null, onclick =null, customFields=null, customClass, page_name, form_name, formTitle, endpoint, extraValues=null}) {
  
  const [DataRendered, setDataRendered] = useState(false);
    useEffect(()=>{
      setDataRendered(true)
    },[])
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
      setIsModalOpen(true);
    };
    
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const options = {
      btn_name: "Submit"
    }

    const phoneRegExp = /^(\+\d{1,4})?(\d{9,14})$/

    const fields = [
      {
        name: 'name',
        label: '',
        placeholder: 'Full Name*',
        validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
        customClass: 'md:col-span-6 col-span-12'
      },
      {
        name: 'email',
        type: 'email',
        label: '',
        placeholder: 'Add Your Company E-mail Address*',
        validation: Yup.string().email('Invalid email').required('Email is required'),
        customClass: 'md:col-span-6 col-span-12'
      
      },
      {
        name: 'company_name',
        label: '',
        placeholder: 'Company Name',
        customClass: 'md:col-span-6 col-span-12'
      },
      {
        name: 'phone',
        label: '',
        placeholder: 'Phone Number',
        validation: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        customClass: 'md:col-span-6 col-span-12'
      },
      {
          name: 'message',
          label: '',
          placeholder: 'Please describe your Competitive Intelligence needs briefly',
          type: 'textarea',
          col_row: 2,
          customClass: 'md:col-span-12 col-span-12'
      },
    ];
  return (
    <>
      {(DataRendered) && 
        (buttonLink) ?
        <Link href={buttonLink}>
          <button className={"text-black border w-fit rounded px-4 py-1" + " " + customClass}>
            {buttonTitle ? buttonTitle: ''}
          </button>
        </Link>
        :
        (onclick)?
        <>
          <button onClick={openModal} className={"text-black border w-fit rounded px-4 py-1" + " " + customClass}>
            {buttonTitle ? buttonTitle: ''}
          </button>
          <CommonPopup 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            fields={customFields ? customFields : fields }
            custom_fields={extraValues}
            options={options}
            page_name={page_name}
            product_name={product_name}
            form_name={form_name}
            formTitle={formTitle}
            endpoint={endpoint}
            slug={slug}
          />
        </>
        :
        <>
          <button className={"text-black border w-fit rounded px-4 py-1" + " " + customClass}>
            {buttonTitle ? buttonTitle: ''}
          </button>
        </>
      }
    </>
  )
}
